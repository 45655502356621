let container = null;

export const openMenu = function(e) {
    // Default
    e.preventDefault();
    container = document.getElementById(e.target.getAttribute('data-container'));
    container.style.display = null;
    container.removeAttribute('aria-hidden');
    container.setAttribute('aria-modal', 'true');
    if (container.id === "profilePreview") {
        container.setAttribute('data-user', document.getElementById("profilePart").getAttribute('data-user'))
        document.getElementById('mainAppHome').style.zIndex = '';
    }
    container.addEventListener('click', closeMenu);
    if(container.querySelector('.js-menu-close')) {
        container.querySelector('.js-menu-close').addEventListener('click', closeMenu);
    }
    if(container.querySelector('.js-menu-close-other')) {
        container.querySelectorAll('.js-menu-close-other').forEach((e) => {
            e.addEventListener('click', closeMenu);
        })
    }
    container.querySelector('.js-menu-stop').addEventListener('click', stopPropagation);

    // ...
    const menuHeight = container.querySelector(".menu-wrapper").scrollHeight;

    if(e.target.getAttribute('data-container').includes("moreBM") || e.target.getAttribute('data-container') === "shareBM") {
        var heightDiv = menuHeight - 10;
        container.querySelector(".menu-wrapper").style.marginTop = "calc("+e.clientY+"px - "+heightDiv+"px)";
        // MORE MENU
        if (e.target.getAttribute('data-container').includes("moreBM")) {
            container.querySelector(".menu-wrapper").style.marginLeft = "355px";
        }else {
            document.querySelector("#shareBM .menu-wrapper").style.marginLeft = "-150px";
        }
        // ...
        container.querySelector(".menu-wrapper").scrollIntoView(true);
    }
    if(container.getAttribute('data-spost') === "false") {
        var heightDiv = menuHeight - 10;
        container.querySelector(".menu-wrapper").style.marginTop = "calc("+e.clientY+"px - "+heightDiv+"px)";
        container.querySelector(".menu-wrapper").scrollIntoView(true);
        if(container.getAttribute('data-mod') === "true" && container.getAttribute('data-mod') !== null) {
            heightDiv = menuHeight - 140;
            container.querySelector(".menu-wrapper").style.marginTop = "calc("+e.clientY+"px - "+heightDiv+"px)";
        }
    }
    if (e.target.getAttribute('data-container') === "logoutMenu" || container.getAttribute('data-o-container') !== null) {
        // Get the size of the entire webpage
        const windowHeight = window.innerHeight;
        const pageWidth  = document.documentElement.scrollWidth;
        var heightDiv = menuHeight + 30;
        
        // If page is settings
        if (window.location.href.includes('/settings')) {
            if(pageWidth <= 768) {
                container.querySelector(".menu-wrapper").style.left = "20px";
                if (container.getAttribute('data-o-container') === "deleteAccMenu") { container.querySelector(".menu-wrapper").style.left = "130px"; }
                if(pageWidth <= 650) {
                    heightDiv = menuHeight - 170;
                    container.querySelector(".menu-wrapper").style.left = "calc("+e.clientX+"px - 220px)";
                    if (container.getAttribute('data-o-container') === "deleteAccMenu") {
                        container.querySelector(".menu-wrapper").style.left = "10px";
                        heightDiv = menuHeight + 20;
                    }
                }
            }else if (pageWidth <= 1200) {
                container.querySelector(".menu-wrapper").style.left = "100px";
                if (container.getAttribute('data-o-container') === "deleteAccMenu") container.querySelector(".menu-wrapper").style.left = "300px";
            }else {
                container.querySelector(".menu-wrapper").style.left = "120px";
                if (container.getAttribute('data-o-container') === "deleteAccMenu") container.querySelector(".menu-wrapper").style.left = "300px";
            }
        }

        // If page is not settings
        if (container.getAttribute('data-o-container') === "moreMenuFriends") heightDiv = menuHeight - 110;
        // ------------------------
        // -- FOR CHAT ACTIONS MENU
        if (container.getAttribute('data-o-container') === "actionsMenuFriends") {
            if(container.getAttribute('data-actions') === "reply,copy"){
                heightDiv = menuHeight - 100;
            }else if(container.getAttribute('data-actions') === "edit,reply,copy"){
                if (e.clientY + menuHeight > windowHeight) {
                    heightDiv = menuHeight + 10;
                }else{
                    heightDiv = menuHeight - 140;
                }
            }else if(container.getAttribute('data-actions') === "edit,reply,copy,delete"){
                if (e.clientY + menuHeight > windowHeight) {
                    heightDiv = menuHeight + 10;
                }else{
                    heightDiv = menuHeight - 180;
                }
            }
        }
        // -- END OF CHAT ACTIONS MENU
        // ---------------------------
        container.querySelector(".menu-wrapper").style.marginTop = "calc("+e.clientY+"px - "+heightDiv+"px)";
        container.querySelector(".menu-wrapper").scrollIntoView(true);
    }
    if(container.getAttribute('data-mod') === null) document.querySelector('.headerMain_pcverSB1').style.zIndex = "99";
    if(e.target.getAttribute('data-blocked') === "true") document.querySelector('#moreProfile .menu-wrapper').style.marginLeft = "calc("+e.clientX+"px - 48%)";
    document.body.scrollTo(0,0);
    //document.body.style.overflow = "hidden";
    /*document.querySelectorAll('#mBLogged.notif').forEach(all => {
        all.style.bottom = "270px";
        all.style.top = "auto";
    });*/
}

export const closeMenu = function(e) {
    if (container === null) return;
    e.preventDefault();
    container.setAttribute('aria-hidden', 'true');
    container.removeAttribute('aria-modal');
    container.removeEventListener('click', closeMenu);
    if(container.querySelector('.js-menu-close')) {
        container.querySelector('.js-menu-close').removeEventListener('click', closeMenu);
    }
    if(container.querySelector('.js-menu-close-other')) {
        container.querySelectorAll('.js-menu-close-other').forEach((e) => {
            e.removeEventListener('click', closeMenu);
        })
    }
    container.querySelector('.js-menu-stop').removeEventListener('click', stopPropagation);
    const hideMenu = function () {
        container.style.display = "none";
        container.removeEventListener('animationend', hideMenu);
        container = null;
    }
    container.addEventListener('animationend', hideMenu);
    if(container.getAttribute('data-mod') === null) {
        document.querySelector('.headerMain_pcverSB1').style.zIndex = "2";
    }
    if (container.id === "profilePreview") {
        document.getElementById('mainAppHome').style.zIndex = '-1';
    }
    /*document.querySelectorAll('#mBLogged.notif').forEach(all => {
        all.style.top = "215px";
        all.style.bottom = "auto";
    });*/
}

const stopPropagation = function(e) {
    e.stopPropagation();
}

window.addEventListener('keydown', function (e) {
    if (e.key === "Escape" || e.key === "Esc") {
        closeMenu(e);
    }
})